C<script>
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";
import { TheMask } from "vue-the-mask";
import { tippy } from "vue-tippy";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "HibahPartA",
  data() {
    return {
      heirs: [],
      newRelationship: null,
      form: {
        name: null,
        relationship: null,
        ic_number: null,
        email: null,
        mobile_number: null,
        home_phone_number: null,
        address: null,
        postcode: null,
        city: null,
        state: null,
        period: null,
        distribution_lifetime_instruction: null,
        distribution_after_death_instruction: null,
      },
      submit: false,
      receivers: null,
      formPostcode: {},
      postcode: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      relationship: {
        required,
      },
      ic_number: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile_number: {
        required,
      },
      address: {
        required,
      },
      postcode: {
        required,
      },
    },
  },
  mounted() {
    this.getReceivers();
    this.getHeirs();
  },
  methods: {
    openModalReceiver() {
      this.$v.$reset();
      this.form.name = null;
      this.form.relationship = null;
      this.form.ic_number = null;
      this.form.email = null;
      this.form.mobile_number = null;
      this.form.home_phone_number = null;
      this.form.address = null;
      this.form.postcode = null;
      this.form.city = null;
      this.form.state = null;
      this.form.sameAddress = null;
      this.form.period = null;
      this.form.distribution_lifetime_instruction = null;
      this.form.distribution_after_death_instruction = null;

      this.$refs["modalAddReceiver"].show();
    },
    hideModal() {
      this.$refs["modalAddReceiver"].hide();
    },
    openpostcode() {
      this.$refs["modalPostcode"].show();
      // modalPostcde;
    },
    hideModalPostcode() {
      this.$refs["modalPostcode"].hide();
    },
    getPostcode() {
      if (this.form.postcode) {
        ApiService.get(`postcode/${this.form.postcode}`).then((response) => {
          var postcode = response.data.postcode;
          if (postcode != null) {
            this.form.city = postcode.city;
            this.form.state = postcode.state;
          }
        });
      }
    },
    async storePostcode() {
      await ApiService.post(`postcode`, this.formPostcode).then((response) => {
        this.postcode = this.formPostcode.postcode;
      });
      await this.getPostcode();
      this.formPostcode = {};
      this.$swal.fire({
        icon: "success",
        html: "Poskod baharu telah didaftarkan.",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tutup",
      });
    },
    getHeirs() {
      ApiService.get(`hibah/${this.hibahId}/heir`)
        .then((response) => {
          this.heirs = response.data.heirs.data ?? [];
        })
        .catch((error) => {});
    },
    async submitForm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal.fire({
          icon: "error",
          html: "Sila isi ruangan yang bertanda (*)",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Tutup",
        });
        return;
      }
      await this.storeReceiver();
      this.hideModal();
    },
    copyAddress() {
      if (this.form.sameAddress == 1) {
        this.form.address = this.trust.user_detail.correspondence_address;
        this.form.city = this.trust.user_detail.correspondence_city;
        this.form.state = this.trust.user_detail.correspondence_state;
        this.form.postcode = this.trust.user_detail.correspondence_postcode;
      } else {
        this.form.address = "";
        this.form.city = "";
        this.form.state = "";
        this.form.postcode = "";
      }
    },
    async getReceivers() {
      return await ApiService.get(`hibah/${this.hibahId}/receiver`).then(
        (response) => {
          this.receivers = response.data.receivers.data ?? [];
        }
      );
    },
    storeReceiver() {
      ApiService.post(`hibah/${this.hibahId}/receiver`, this.form).then(
        (response) => {
          this.receivers.unshift(response.data.receiver);
        }
      );
    },
    removeReceiver(receiverId) {
      ApiService.post(`receiver/${receiverId}`, {
        _method: "DELETE",
      }).then((response) => {
        this.getReceivers();
        // this.receivers.unshift(response.data.receiver);
      });
    },
    async changeRelationship() {
      if (["Others", "Lain-lain"].includes(event.target.value)) {
        const { value: newRelationship } = await this.$swal.fire({
          html:
            "<span class='form-group'><label style='margin-bottom:0px'>Hubungan</label></span>",
          input: "text",
        });
        if (newRelationship) {
          console.log(newRelationship);
          this.newRelationship = newRelationship;
          this.form.relationship = newRelationship;
        } else {
          this.form.relationship = this.relationship[0];
        }
      }
    },
    fillSaksiForm() {
      var heir = this.heirs[this.form.currentIndex];
      if (this.form.currentIndex >= 0) {
        this.form.name = heir.name;
        this.form.relationship = heir.relationship;
        this.form.ic_number = heir.ic_number;
        this.form.email = heir.email;
        this.form.mobile_number = heir.mobile_number;
        this.form.home_phone_number = heir.home_phone_number;
        this.form.address = heir.address;
        this.form.postcode = heir.postcode;
        this.form.city = heir.city;
        this.form.state = heir.state;
        this.form.newHeir = false;
      }
      if (this.form.currentIndex == -1) {
        this.form.name = null;
        this.form.relationship = null;
        this.form.ic_number = null;
        this.form.email = null;
        this.form.mobile_number = null;
        this.form.home_phone_number = null;
        this.form.address = null;
        this.form.postcode = null;
        this.form.city = null;
        this.form.state = null;
        this.form.newHeir = true;
      }
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  computed: {
    hibahId() {
      return this.$route.query.hibah_id;
    },
    ...mapGetters(["hibah"]),
    allowAddReceiver() {
      if (this.preference && this.preference.hibah_single_receiver == 1) {
        if (this.receivers && this.receivers.length >= 1) {
          return false;
        }
      }
      return true;
    },
    addReceiver() {
      if (
        this.trust &&
        [3, 4, 5].includes(this.trust.status) &&
        !this.amendmentId
      ) {
        return false;
      } else if (
        this.amendmentId &&
        this.amendment &&
        ![0, 1].includes(this.amendment.status)
      ) {
        return false;
      } else {
        return true;
      }
    },
    routeMessage() {
      return this.$route.query.message;
    },
    relationships() {
      var relationships = this.$t("relationships").split(",");
      if (this.newRelationship) {
        relationships.push(this.newRelationship);
      } else if (!relationships.includes(this.form.relationship)) {
        relationships.push(this.form.relationship);
      }
      return relationships;
    },
  },
  watch: {
    locale: function () {
      if (!this.newRelationship) this.form.relationship = this.relationship[0];
    },
    trust: {
      handler: function (val) {
        if (val && val.status == -1) {
          this.$router.push({
            name: "trust.part-c",
            query: {
              trust_id: this.hibahId,
            },
          });
        }
      },
    },
  },
  components: { TheMask },
};
</script>

<template>
  <div class="col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
    <div class="row">
      <div class="col-md-12" v-if="routeMessage == 'receiver-updated'">
        <div class="alert alert-success">
          {{ $t("trust.beneficiary-updated") }}
        </div>
      </div>
      <div class="col-lg-12 col-12 mb-4 pb-2">
        <div
          class="card bg-light job-box rounded shadow border-0 overflow-hidden"
        >
          <div class="card-bodyz">
            <h5 class="text-center mb-5 mt-4">
              Maklumat Penerima
              <img
                content="Senarai penerima yang akan diagihkan dalam harta"
                v-tippy
                height="20"
                class="mb-1"
                src="/images/icon/info-button.png"
              />
            </h5>

            <div class="text-right mr-2" v-if="addReceiver">
              <button @click="openModalReceiver" class="btn btn-primary btn-sm">
                {{ $t("add") }}
              </button>
            </div>
            <div class="row mb-2">
              <div class="col-lg-12">
                <div class="table-responsive p-2 mt-3 mb-4">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>{{ $t("name") }}</th>
                        <th>{{ $t("mykad") }}</th>
                        <th>{{ $t("relationship") }}</th>
                        <th>{{ $t("email") }}</th>
                        <th>{{ $t("mobile-no") }}</th>
                        <th>Tempoh Amanah</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(receiver, index) in receivers" :key="index">
                        <td>{{ receiver.name }}</td>
                        <td>{{ receiver.ic_number }}</td>
                        <td>{{ receiver.relationship }}</td>
                        <td>{{ receiver.email }}</td>
                        <td>{{ receiver.mobile_number }}</td>
                        <td></td>
                        <td class="text-right">
                          <div
                            class="btn-group-vertical"
                            role="group"
                            aria-label="Vertical button group"
                            v-if="addReceiver"
                          >
                            <router-link
                              class="btn btn-secondary btn-sm"
                              :to="{
                                name: 'trust-inheritance.receiver.update',
                                query: {
                                  hibah_id: hibahId,
                                  receiver_id: receiver.id,
                                },
                              }"
                              >{{ $t("update") }}</router-link
                            >
                            <button
                              @click="removeReceiver(receiver.id)"
                              class="btn btn-danger btn-sm"
                            >
                              {{ $t("remove") }}
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="receivers && receivers.length == 0">
                        <td colspan="6" class="text-center">
                          <h5 class="mt-5 mb-5">{{ $t("no-data") }}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 text-right">
            <div class="form-group">
              <div class="d-flex justify-content-between"></div>
              <router-link
                :to="{
                  name: 'trust-inheritance.property.immoveable',
                  query: {
                    hibah_id: hibahId,
                  },
                }"
                class="btn btn-primary"
              >
                {{ $t("save-next") }}
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="submit"
                ></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :no-enforce-focus="true"
      title="Tambah Penerima"
      ref="modalAddReceiver"
      class="modal fade"
      v-bind:ok-title="$t('add')"
      v-bind:cancel-title="$t('cancel')"
      @ok="submitForm"
    >
      <div class="modal-body">
        <div class="form-group">
          <label for="current_user">Pilih Penerima</label>
          <select
            name
            id="current_user"
            class="form-control"
            v-model="form.currentIndex"
            @change="fillSaksiForm"
          >
            <option value="-2"></option>
            <option value="-1">-- Tambah Penerima Baru --</option>
            <option :value="index" v-for="(heir, index) in heirs" :key="index">
              {{ heir.name }}
            </option>
          </select>
        </div>
        <div v-if="form.currentIndex >= -1">
          <div class="form-group" :class="{ 'has-error': $v.form.name.$error }">
            <label>
              {{ $t("name") }}
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              v-uppercase
              v-model.trim="$v.form.name.$model"
              class="form-control"
            />
            <div
              class="form-error"
              v-if="$v.form.name.$error && !$v.form.name.required"
            >
              {{ $t("name") }} {{ $t("validation.cannot-blank") }}
            </div>
          </div>
          <div
            class="form-group"
            :class="{ 'has-error': $v.form.relationship.$error }"
          >
            <label>
              {{ $t("relationship") }}
              <span class="text-danger">*</span>
            </label>
            <select
              name="relationship"
              id="relationship"
              v-model.trim="$v.form.relationship.$model"
              class="form-control"
              @change="changeRelationship"
            >
              <option value>-- {{ $t("choose.relationship") }} --</option>
              <option
                v-for="relationship in relationships"
                :key="relationship"
                :value="relationship"
              >
                {{ relationship }}
              </option>
            </select>
            <div
              class="form-error"
              v-if="
                $v.form.relationship.$error && !$v.form.relationship.required
              "
            >
              {{ $t("relationship") }} {{ $t("validation.cannot-blank") }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-error': $v.form.ic_number.$error }"
              >
                <label>
                  {{ $t("mykad") }}
                  <span class="text-danger">*</span>
                </label>
                <the-mask
                  id="ic_number"
                  :mask="['######-##-####']"
                  class="form-control"
                  v-model.trim="$v.form.ic_number.$model"
                />
                <div
                  class="form-error"
                  v-if="$v.form.ic_number.$error && !$v.form.ic_number.required"
                >
                  {{ $t("validation.mykad") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-error': $v.form.email.$error }"
              >
                <label>
                  {{ $t("email") }}
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="email"
                  v-model.trim="$v.form.email.$model"
                />
                <div class="form-error" v-if="!$v.form.email.email">
                  Anda telah memasukkan alamat emel yang tidak sah!
                </div>
                <div
                  class="form-error"
                  v-if="$v.form.email.$error && !$v.form.email.required"
                >
                  {{ $t("validation.email") }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div
                class="form-group"
                :class="{ 'has-error': $v.form.mobile_number.$error }"
              >
                <label>
                  {{ $t("mobile-no") }}
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="mobile_number"
                  v-model.trim="$v.form.mobile_number.$model"
                />
                <div
                  class="form-error"
                  v-if="
                    $v.form.mobile_number.$error &&
                    !$v.form.mobile_number.required
                  "
                >
                  {{ $t("validation.mobile-no") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ $t("home-telno") }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="home_phone_number"
                  v-model="form.home_phone_number"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                class="form-group"
                :class="{ 'has-error': $v.form.address.$error }"
              >
                <label
                  >{{ $t("mailing-address")
                  }}<span class="text-danger">*</span></label
                >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="form.sameAddress"
                    @change="copyAddress"
                    value="1"
                  />
                  <label class="custom-control-label" for="customCheck1">
                    Alamat Surat Menyurat Sama Seperti Pemberi
                  </label>
                </div>
                <textarea
                  v-uppercase
                  class="form-control"
                  :readonly="form.sameAddress == 1"
                  id="address"
                  rows="2"
                  v-model.trim="$v.form.address.$model"
                ></textarea>
                <div
                  class="form-error"
                  v-if="$v.form.address.$error && !$v.form.address.required"
                >
                  {{ $t("mailing-address") }} {{ $t("validation.cannot-blank") }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div
                class="form-group"
                :class="{ 'has-error': $v.form.postcode.$error }"
              >
                <label for="postcode" class="d-block">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      >{{ $t("postcode")
                      }}<span class="text-danger">*</span></span
                    >
                    <span
                      @click="openpostcode"
                      style="
                        text-decoration: underline;
                        font-size: 11px;
                        color: #333;
                      "
                      >{{ $t("add-new") }}</span
                    >
                  </div>
                </label>
                <input
                  type="text"
                  id="postcode"
                  class="form-control"
                  @blur="getPostcode"
                  :readonly="form.sameAddress == 1"
                  v-model.lazy.trim="$v.form.postcode.$model"
                />
                <div
                  class="form-error"
                  v-if="$v.form.postcode.$error && !$v.form.postcode.required"
                >
                  {{ $t("validation.postcode") }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="city">{{ $t("city") }}</label>
                <input
                  type="text"
                  v-uppercase
                  id="city"
                  v-model="form.city"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="state">{{ $t("state") }}</label>
                <input
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="state"
                  v-model="form.state"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label for="">Amanah akan berakhir apabila:</label>
              <div>
                <span style="display: inline">
                  Tempoh sehingga
                  <input
                    type="date"
                    v-model="form.period"
                    class="form-control"
                    style="width: 40%; display: inherit"
                  />
                  tapi tertakluk kepada kebenaran AMANAH WARISAN BERHAD.</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label
                  >Pengagihan dan Pembahagian Selepas Kematian Pemberi</label
                >
                <textarea
                  name=""
                  cols="30"
                  rows="10"
                  class="form-control"
                  v-model="form.distribution_after_death_instruction"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modalPostcode"
      class="modal fade"
      v-bind:title="$t('add-newpostcode')"
      v-bind:ok-title="$t('save')"
      v-bind:cancel-title="$t('cancel')"
      @ok="storePostcode"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>
              {{ $t("postcode") }}
              <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              v-model="formPostcode.postcode"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>
              {{ $t("city") }}
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              v-uppercase
              class="form-control"
              v-model="formPostcode.city"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ $t("state") }}
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              v-uppercase
              class="form-control"
              v-model="formPostcode.state"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
